import {easepick} from "@easepick/core";
import {RangePlugin} from "@easepick/range-plugin";
import {AmpPlugin} from "@easepick/amp-plugin";
import {LockPlugin} from "@easepick/lock-plugin";
import {PresetPlugin} from "@easepick/preset-plugin";
import {DateTime} from "luxon";
import {dateConvert} from "../shared/text_utilities";
import {manifest} from "../shared/common";

$( () => {

    const $containers = $('#box-list .date-container, .box_list #filter .date-container').not('.disabled');

    $containers.each(function(){

        const $container = $(this);

        let date = {
            'start': {},
            'end': {},
        };

        $(this).find('.d-ib').each(function(){
            const key = ( $(this).index() === 0 ) ? 'start' : 'end';
            const index = $(this).index();
            date[key].text = $container.find('label').eq(index).text();
            date[key].$input = $container.find('input').eq(index);
            date[key].id = $container.find('input').eq(index).attr('id');
            date[key].value = date[key].$input.val() ?? false;
        });

        const id = `${date.start.id}_${date.end.id}`;
        const clearInitialState = ( date.start.value || date.end.value ) ? '' : ' style="display:none" ';
        const iconInitialState = ( date.start.value || date.end.value ) ? 'style="display:none" ' : '';
        const html = `<div class="d-ib">
            <label>${$container.attr('data')}</label>
            <input type="text" class="easepick" id="${id}" placeholder="From/To">
            <i class="fa fa-calendar-alt grey" title="Choose date" id="${id}_icon" ${iconInitialState}></i>
            <i class="fa fa-times red" title="Clear" id="${id}_clear" ${clearInitialState}></i>
        </div>`;
        $container.find('.d-ib').hide().last().after(html);
        const input = $container.find(`#${id}`)[0];
        const $clear = $container.find(`#${id}_clear`);
        const $icon = $container.find(`#${id}_icon`);

        // insert html
        let ep = new easepick.create({
            element: input,
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
                manifest('css/easepick.css')
            ],
            format: "DD MMM YYYY",
            calendars: 1,
            AmpPlugin: {
                resetButton: true,
                dropdown: {
                    years: true,
                    months: true,
                    minYear: 2017,
                },
            },
            PresetPlugin: {
                position: 'left',
                customPreset: {
                    'Today': [DateTime.now().toJSDate(), DateTime.now().toJSDate()],
                    'Last 7 Days': [DateTime.now().minus({days: 6}).toJSDate(), DateTime.now().toJSDate()],
                    'Last 14 Days': [DateTime.now().minus({days: 13}).toJSDate(), DateTime.now().toJSDate()],
                    'Last 28 Days': [DateTime.now().minus({days: 27}).toJSDate(), DateTime.now().toJSDate()],
                    'Last Month': [DateTime.now().minus({months: 1}).startOf('month').toJSDate(), DateTime.now().minus({months: 1}).endOf('month').toJSDate()],
                    'Last 3 Months': [DateTime.now().startOf('month').minus({months: 3}).toJSDate(), DateTime.now().minus({months: 1}).endOf('month').toJSDate()],
                    'This Month': [DateTime.now().startOf('month').toJSDate(), DateTime.now().toJSDate()],
                    'This Year': [DateTime.now().startOf('year').toJSDate(), DateTime.now().toJSDate()],
                },
            },
            LockPlugin: {
                maxDate: DateTime.now().endOf('month').toFormat('dd MMM yyyy'),
            },
            plugins: [RangePlugin, AmpPlugin, LockPlugin, PresetPlugin],
            zIndex: 100,
        });

        if( date.start.value ) {
            ep.setStartDate(DateTime.fromISO(dateConvert(date.start.value)).toFormat('dd MMM yyyy'));
        }
        if( date.end.value ) {
            ep.setEndDate(DateTime.fromISO(dateConvert(date.end.value)).toFormat('dd MMM yyyy'));
        }

        ep.on('select', dates => {
            const start = DateTime.fromJSDate(dates.detail.start).setLocale('gb').toFormat('dd-MM-yyyy');
            const end = DateTime.fromJSDate(dates.detail.end).setLocale('gb').toFormat('dd-MM-yyyy');
            date.start.$input.val(start);
            date.end.$input.val(end);
            $clear.show();
            $icon.hide();

            if(app.DASHBOARD_CORE) {
                app.DASHBOARD_CORE.dateFilterUpdate(date);
            }
        });

        ep.on('clear', () => {
            date.start.$input.val('');
            date.end.$input.val('');
            $clear.hide();
            $icon.show();

            if(app.DASHBOARD_CORE) {
                app.DASHBOARD_CORE.dateFilterUpdate(date);
            }
        });

        $clear.on('click', () => {
           ep.clear();
        });

        $icon.on('click', () =>{
            input.dispatchEvent(new Event('click'));
        });

        $container.removeClass('date-container').addClass('easepick-container');
    });

    // hack to position container either right side of input
    if( $containers.length ) {
        document.querySelector('input.easepick').addEventListener('click', e => {
                const $input = $(e.currentTarget);
                const $containerReal = $input.next();
                const $containerShadow = $($containerReal[0].shadowRoot).find('.container');
                const widthWindow = window.innerWidth;
                const widthWindowMinResize = 600;
                const widthModal = $containerShadow.width();
                const xPosition = $input.offset().left;
                const xDifference = widthWindow - xPosition;

                // reset container positions
                $containerShadow.css('left', 0);
                $containerReal.css('left', 0);

                if( xDifference < widthModal && widthWindow >= widthWindowMinResize ) {
                    $containerReal.css('left', 'auto');
                    $containerReal.css('right', widthModal);
                    console.log('right position');
                } else {
                    console.log('left position');
                }
        });
    }
});